import * as React from 'react';

import MainLayout from '../../../layouts/MainLayout';
import { Seo, Box, Text, Link } from '../../../components';

const PrivacyPolicy = () => {
  return (
    <MainLayout>
      <Seo title="SAPP Quora Pixel Privacy Policy" />
      <Box px={[0, 100]} py={50} width={[1, 1, 960]} m="0 auto">
        <Text variant="h2" mb={3}>
          SAPP Quora Pixel Privacy Policy
        </Text>
        <Text mb={4}>
          SAPP Quora Pixel "the App” provides event tracking through Quora pixel
          "the Service" to merchants who use Shopify to power their stores. This
          Privacy Policy describes how personal information is collected, used,
          and shared when you install or use the App in connection with your
          Shopify-supported store.
        </Text>
        <Text variant="h2" mb={3}>
          Personal Information the App Collects
        </Text>
        <Text mb={3}>
          When you install the App, we are automatically able to access certain
          types of information from your Shopify account. You will be asked to
          provide your Quora pixel code.
        </Text>
        <Text mb={4}>
          We collect personal information directly from the relevant individual,
          through your Shopify account, or using the following technologies:
          “Cookies” are data files that are placed on your device or computer
          and often include an anonymous unique identifier. For more information
          about cookies, and how to disable cookies, visit{' '}
          <Link to="http://www.allaboutcookies.org">
            http://www.allaboutcookies.org
          </Link>
          . “Log files” track actions occurring on the Site, and collect data
          including your IP address, browser type, Internet service provider,
          referring/exit pages, and date/time stamps. “Web beacons,” “tags,” and
          “pixels” are electronic files used to record information about how you
          browse the Site.
        </Text>
        <Text variant="h2" mb={3}>
          How Do We Use Your Personal Information?
        </Text>
        <Text mb={3}>
          We use the personal information we collect from you and your customers
          in order to provide the Service and to operate the App. Additionally,
          we use this personal information to: Communicate with you; Optimize or
          improve the App; and Provide you with information or advertising
          relating to our products or services.
        </Text>
        <Text variant="h2" mb={3}>
          Sharing Your Personal Information
        </Text>
        <Text mb={3}>
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights. Behavioural Advertising As described
          above, we use your Personal Information to provide you with targeted
          advertisements or marketing communications we believe may be of
          interest to you. For more information about how targeted advertising
          works, you can visit the Network Advertising Initiative’s (“NAI”)
          educational page at{' '}
          <Link to="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">
            http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
          </Link>
          .
        </Text>
        <Text mb={3}>
          You can opt out of targeted advertising from GOOGLE, FACEBOOK, BING
          etc. Additionally, you can opt out of some of these services by
          visiting the Digital Advertising Alliance’s opt-out portal at:{' '}
          <Link to="http://optout.aboutads.info/">
            http://optout.aboutads.info/
          </Link>
          .
        </Text>
        <Text variant="h2" mb={3}>
          Your Rights
        </Text>
        <Text mb={4}>
          If you are a European resident, you have the right to access personal
          information we hold about you and to ask that your personal
          information be corrected, updated, or deleted. If you would like to
          exercise this right, please contact us through the contact information
          below.
        </Text>
        <Text mb={4}>
          Additionally, if you are a European resident we note that we are
          processing your information in order to fulfill contracts we might
          have with you (for example if you make an order through the Site), or
          otherwise to pursue our legitimate business interests listed above.
          Additionally, please note that your information will be transferred
          outside of Europe, including to Canada and the United States.
        </Text>
        <Text variant="h2" mb={3}>
          Data Retention
        </Text>
        <Text mb={4}>
          When you place an order through the Site, we will maintain your Order
          Information for our records unless and until you ask us to delete this
          information.
        </Text>
        <Text variant="h2" mb={3}>
          Changes
        </Text>
        <Text mb={4}>
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </Text>
        <Text variant="h2" mb={3}>
          Contact Us
        </Text>
        <Text mb={4}>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at sapp.support@springrain.io or by mail using the details
          provided below:
        </Text>
        <Text>Spring Rain Private Ltd</Text>
        <Text>
          7th Floor, Commercial Cove, House 56/C, Road 132, Dhaka-1212
        </Text>
      </Box>
    </MainLayout>
  );
};

export default PrivacyPolicy;
